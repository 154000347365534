import React, { useState } from "react";
import axios from "axios";


function Form() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mob: '',
    company_name: '',
    message: ''
  });

  const [loading, setLoading] = useState(false); // to manage loading state
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const[message,setMessage]=useState();

  const apiUrl = process.env.REACT_APP_API_KEY;
  console.log(apiUrl)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({  
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);
    

    try {
      const response = await axios.post(`${apiUrl}/contactus/`, formData);
      console.log(response);
      if ((response.status === 201 || response.status === 200)  && response.data.message) {
       
        console.log("Form submitted successfully");
        setSuccess(true);
        setMessage(response.data.message);
        setFormData({
          name: '',
          email: '',
          mob: '',
          company_name: '',
          message: ''
        });
        e.target.reset(); // Reset the form fields
      }
    } catch (err) {
      setError("Failed to submit form. Please try again.");
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-3 md:space-y-6 my-4 flex flex-col justify-center items-center sm:max-w-[40rem] md:max-w-[50rem] mx-auto px-3 md:px-6">
      <h2 className="font-semibold text-3xl">Connect with us</h2>
      <p className="text-center">
        Share your details for customer assistance, business &amp; partnership
        enquiries. Our team will get back to you shortly.
      </p>

      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500 text-2xl font-serif italic ">{message}</p>}

      <form className="px-2 my-2 md:px-12 w-full flex flex-col justify-center" onSubmit={handleSubmit}>
        <div>
          <input
            className="input"
            type="text"
            name="name"
            placeholder="Name *"
            required
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div>
          <input
            className="input"
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div>
          <input
            className="input"
            type="text"
            name="mob"
            placeholder="Mobile Number *"
            required
            value={formData.mob}
            onChange={handleChange}
          />
        </div>

        <div>
          <input
            className="input"
            type="text"
            name="company_name"
            placeholder="Company Name"
            value={formData.company_name}
            onChange={handleChange}
          />
        </div>

        <div>
          <textarea
            className="input"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>

        <button
          className="bg-sky-400 text-xl text-white rounded-md py-2 px-7 font-semibold mx-auto width"
          type="submit"
          disabled={loading} // Disable button while loading
        >
          {loading ? 'Sending...' : 'Send'}
        </button>
      </form>
    </div>
  );
}

export default Form;
