import { useState } from "react";
import { IoMenuOutline } from "react-icons/io5";
import { Link } from "react-scroll";
import { IoMdArrowDropdown } from "react-icons/io";

// Dropdown component to encapsulate dropdown logic
const Dropdown = ({ showDropdown, toggleDropdown, handleDropdownClick }) => (
  <div className="relative">
    <div className="flex md:px-10">
      <button onClick={toggleDropdown} className="dropdown-toggle font-bold" type="button">
        What We Do
      </button>
      <IoMdArrowDropdown className="mt-1.5" onClick={toggleDropdown} />
    </div>
    {showDropdown && (
      <ul className="absolute shadow-md mt-2 px-2 rounded-md font-mono font-bold text-[15px] bg-white md:bg-white/40 min-w-[200px] lg:w-full z-50">
        {[
          { label: "IoT dashboard Login Page", url: "https://login.bc-pl.com/" },
          { label: "Aquaculture Canada", url: "https://canada.bc-pl.com/" },
          { label: "Aquaculture Australia", url: "https://australia.bc-pl.com/" },
        ].map((item) => (
          <li
            key={item.label}
            className="p-2 hover:text-zinc-800 cursor-pointer transform transition-transform duration-300 hover:scale-105 md:text-white text-black whitespace-nowrap"
            onClick={() => handleDropdownClick(item.url)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    )}

  </div>
);

function Header() {
  const [showNav, setShowNav] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // State for dropdown visibility

  const handleDropdownClick = (url) => {
    window.open(url, '_blank'); // Open the specified URL in a new tab
  };

  const toggleDropdown = () => setShowDropdown((prev) => !prev); // Toggle dropdown state

  return (
    <>
      <nav className="flex w-full justify-between sm:absolute py-4 items-center  px-4 sm:text-white z-50">
        <h2 className="text-2xl md:text-4xl font-bold lg:pl-8">Bariflo Cybernetics</h2>
        <IoMenuOutline className="text-4xl sm:hidden" onClick={() => setShowNav((prev) => !prev)} />
        <ul className="text-2xl hidden sm:flex">
          <Dropdown showDropdown={showDropdown} toggleDropdown={toggleDropdown} handleDropdownClick={handleDropdownClick} />
          <Link to="about" className="lg:px-6 px-3 lg:mx-6 font-semibold hover:cursor-pointer text-white no-underline">
            News
          </Link>
          <Link to="contact" className="lg:px-6 px-3 lg:mx-6 font-semibold hover:cursor-pointer text-white no-underline">
            Contact
          </Link>
        </ul>
      </nav>

      {showNav && (
        <ul className="text-md text-blue-600 flex justify-between mx-4 py-2 sm:hidden">
          <Dropdown showDropdown={showDropdown} toggleDropdown={toggleDropdown} handleDropdownClick={handleDropdownClick} />
          <Link to="about" smooth={true} duration={500} className="mx-3 font-bold hover:cursor-pointer">
            About
          </Link>
          <Link to="contact" smooth={true} duration={500} className="mx-3 font-bold hover:cursor-pointer">
            Contact
          </Link>
        </ul>
      )}
    </>
  );
}

export default Header;
